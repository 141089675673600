<div class="loader-container" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-light'">
    <div class="loader-logo">
        <ng-lottie *ngIf="isBrowser" [options]="options" width="100px" height="100px"></ng-lottie>

        <ng-container *ngIf="!isBrowser">
            <img [src]="CDN + 'shared/preloader.webp'" width="60px" height="60px" alt="wave" />
            <div class="waviy">
                <span>P</span>
                <span>l</span>
                <span>e</span>
                <span>a</span>
                <span>s</span>
                <span>e</span>
                <span>&nbsp; w</span>
                <span>a</span>
                <span>i</span>
                <span>t</span>
            </div>
        </ng-container>
    </div>
</div>
