import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellMobileComponent } from './core/components/shell-mobile/shell-mobile.component';
import { ShellComponent } from './core/components/shell/shell.component';
import { AuthGuard } from './core/services/guards/auth.guard';
import { AppResolver } from './core/services/resolvers/app.resolver';
import { environment } from 'src/environments/environment';

const routes: Routes = [
    {
        path: 'rewards',
        redirectTo: '',
        pathMatch: 'full'
    },
    // The below 404 route is must to have including wild route because of airdrop landing page resolver (TicketDetailsResolver)
    {
        path: 'page-not-found',
        data: {
            title: 'Page not found'
        },
        loadComponent: () =>
            import('./standalone/components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
    },
    {
        path: 'server-management',
        loadComponent: () =>
            import('./standalone/components/maintenance/maintenance.component').then(c => c.MaintenanceComponent)
    },
    {
        path: '',
        resolve: [AppResolver],
        component: ShellComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemModule),
                title: 'Earn Free Crypto | IXFI Rewards'
            },
            {
                path: 'task-center',
                data: {
                    title: 'Complete Tasks & Earn Free Crypto',
                    meta: {
                        canonical: environment.IXFI_REWARDS + 'task-center',
                        'og:description': `Collect crypto rewards by completing tasks in the IXFI Rewards Center. Participate in various activities, earn IXFI Points, and claim your free crypto rewards now!.`,
                        'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/meta/Task_Meta-Image.png',
                        keywords:
                            'IXFI Task Center, IXFI Rewards Program, Earn Free Crypto, Earn Crypto Rewards, Complete Tasks, Crypto Tasks, Rewards Program, IXFI Points, Crypto Challenges'
                    }
                },
                loadChildren: () => import('./earn/earn.module').then(m => m.EarnModule)
            },
            {
                path: 'leaderboard',
                data: {
                    title: 'Leaderboard',
                    meta: {
                        canonical: environment.IXFI_REWARDS + 'leaderboard',
                        'og:description': `See where you stand on ixfi's leaderboards, track your progress, and compete with other users.`,
                        'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/meta/Leaderboard_Meta-Image.png'
                    }
                },
                loadChildren: () => import('./leaderboard/leader-board.module').then(m => m.LeaderBoardModule)
            },
            {
                path: 'history',
                data: {
                    title: 'History'
                },
                canActivate: [AuthGuard],
                loadChildren: () => import('./history/history.module').then(m => m.HistoryModule)
            },
            {
                path: 'steps',
                data: {
                    title: 'Earn Free Crypto with Our Steps Program',
                    meta: {
                        canonical: environment.IXFI_REWARDS + 'steps',
                        'og:description': `Join IXFI’s Steps Program and receive crypto rewards. Complete steps, accumulate IXFI Points, and redeem free crypto. Start earning with every step you take!.`,
                        'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/meta/Steps_Meta-Image.png',
                        keywords:
                            'IXFI steps program, IXFI Rewards program, IXFI Rewards, Earn Free Crypto, Crypto Rewards, Free Crypto, Earn Crypto, Rewards Program, Crypto Incentives, Crypto Bonuses'
                    }
                },
                loadChildren: () => import('./step-home/step-home.module').then(m => m.StepHomeModule)
            },
            {
                path: '',
                loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule)
            }
        ]
    },
    {
        path: 'mobile',
        resolve: [AppResolver],
        component: ShellMobileComponent,
        children: [
            {
                path: 'leaderboard',
                data: {
                    title: 'Leaderboard'
                },
                loadChildren: () => import('./leaderboard/leader-board.module').then(m => m.LeaderBoardModule)
            },
            {
                path: '',
                loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemModule)
            },
            {
                path: 'steps',
                data: {
                    title: 'Steps'
                },
                loadChildren: () => import('./step-home/step-home.module').then(m => m.StepHomeModule)
            },
            {
                path: '',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule)
                    }
                ]
            }
        ]
    },
    {
        path: '**',
        data: {
            title: 'Page not found'
        },
        loadComponent: () =>
            import('./standalone/components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
