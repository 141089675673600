import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONSTANTS } from 'src/app/shared/models/enum.constants';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-terms-and-conditions-modal',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent implements OnInit, OnDestroy {
    @Input() description!: string;
    @Input() userBanNotice!: boolean;
    safeHTMLContent!: SafeHtml;
    APP_CONSTANTS = APP_CONSTANTS;
    interval!: NodeJS.Timeout;
    constructor(
        private activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        this.safeHTMLContent = this.sanitizer.bypassSecurityTrustHtml(this.description);
        if (isPlatformBrowser(this.platformId)) {
            this.interval = setTimeout(() => {
                const ele = document.getElementById('tnCBody');
                ele?.scrollTo(0, 0);
            }, 0);
        }
    }

    onClose(reason: string) {
        this.activeModal.dismiss(reason);
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
    }
}
