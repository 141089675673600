import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { isPlatformServer } from '@angular/common';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';

import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(
        private authService: AuthService,
        @Optional() @Inject(REQUEST) private httpRequest,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}
    canActivate(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        /**
         * Returns true if the user is Logged In and Platform is server
         *
         */
        if (this.httpRequest?.cookies && isPlatformServer(this.platformId)) {
            const isLoggedIn = this.httpRequest?.cookies[STORAGE_KEY.IS_LOGGED_IN] === 'true';
            return isLoggedIn;
        }
        const isLoggedIn = this.authService.isLoggedIn();
        if (isLoggedIn) return true;
        else {
            this.authService.redirectToPlatformUrl('auth/login', true);
            return false;
        }
    }
}
