import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, map, merge, Observable, of, Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { STORAGE_KEY } from './shared/models/enum.constants';
import { TermsAndConditionsService } from './core/services/terms-and-conditions.service';
import { environment } from '../environments/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { IxfiLanguageService, IxfiThemeService } from 'header';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'ixfi Rewards';
    isLoading: boolean = true;
    online$!: Observable<boolean>;
    isOnline = true;
    isWebView!: boolean;
    isBrowser!: boolean;

    subscriptions: Subscription[] = [];

    ngbModal = inject(NgbModal);
    translate = inject(TranslateService);
    cookieService = inject(CookieService);
    tncService = inject(TermsAndConditionsService);
    language = inject(IxfiLanguageService);
    theme = inject(IxfiThemeService);
    router = inject(Router);
    platformId = inject(PLATFORM_ID);
    authService = inject(AuthService);
    private elementRef = inject(ElementRef);

    constructor(@Optional() @Inject(REQUEST) private httpRequest) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (!this.isBrowser) {
            if (this.httpRequest?.cookies) {
                // SET THEME for SERVER
                const isDarkMode = this.httpRequest?.cookies?.isDarkMode === 'true' || false;
                this.theme.toggleTheme(isDarkMode);

                // SET LANGUAGE for SERVER
                if (this.httpRequest?.cookies?.selectedLanguage) {
                    const lang = JSON.parse(this.httpRequest?.cookies?.selectedLanguage) || null;
                    if (lang) this.language.updateLanguage(lang);
                }
            }
        }

        this.isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
        this.translate.setDefaultLang('en');
        const routerEventSub = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) this.isLoading = true;
            if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)
                this.isLoading = false;
        });
        this.subscriptions.push(routerEventSub);

        if (this.cookieService.get(STORAGE_KEY.IS_LOGGED_IN) && !this.isWebView) {
            this.authService.loginStatus.next(true);
        } else {
            this.authService.clearData();
        }
    }

    ngOnInit(): void {
        if (!this.isWebView) {
            this.checkForNewRelease();
            this.tncService.openTermsAndConditions();
            /**
             * Check If the system is connected to the internet
             * Merge 3 streams and run async pipe so there's concurrency with the result
             */
            this.online$ = merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(map(_ => true)),
                fromEvent(window, 'offline').pipe(map(_ => false))
            );
            this.online$.pipe(
                map(res => {
                    if (res && !this.isOnline) {
                        this.isOnline = true;
                        window.location.reload();
                    } else {
                        this.isOnline = false;
                        this.ngbModal.dismissAll();
                    }
                })
            );
        } else {
            this.online$ = of(true);
        }
        this.hideAngularVersion();
    }

    private checkForNewRelease() {
        if (isPlatformBrowser(this.platformId)) {
            const release$ = this.authService.getRelease({ release_platform: 'web' }).subscribe(release => {
                if (release.data?.current_release?.RELEASE_VERSION !== environment.RELEASE_VERSION) {
                    // Clear caches
                    caches.keys().then((data: any) => {
                        data.forEach((key: string) => {
                            caches.delete(key);
                        });
                    });
                }
            });
            this.subscriptions.push(release$);
        }
    }

    hideAngularVersion() {
        this.elementRef?.nativeElement?.removeAttribute('ng-version');
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
