import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { IxfiLanguageService, Language } from 'header';

let refreshCalled = false;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private languageService: IxfiLanguageService,
        private cookieService: CookieService,
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let authReq = req;
        let header: HttpHeaders = authReq.headers;
        header = header.append('Content-Type', 'application/json');

        const selectedLanguage: Language = this.languageService.selectedLanguage;

        if (selectedLanguage) {
            header = header.append('language', selectedLanguage.language_code.toLowerCase() || 'en');
        }

        // required device type
        header = header.append('device-type', 'web');

        const isLoggedIn = this.authService.isLoggedIn();

        if (isLoggedIn) {
            const token = this.cookieService.get(STORAGE_KEY.TOKEN);
            header = header.append(STORAGE_KEY.TOKEN, token);
            if (!refreshCalled && req.url.includes('refresh-token')) {
                const refreshToken: any = this.cookieService.get(STORAGE_KEY.REFRESH_TOKEN);
                header = header.append('refresh-token', refreshToken);
            }
        }

        authReq = req.clone({ headers: header });

        return next.handle(authReq);
    }
}
