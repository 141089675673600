import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-new-release-modal',
    templateUrl: './new-release-modal.component.html',
    styleUrls: ['./new-release-modal.component.scss'],
    imports: [LottieModule],
    standalone: true
})
export class NewReleaseModalComponent {
    @Input() data!: NgbModalRef;

    CDN_URL = environment.CDN_URL;
    options = { path: '../../../../assets/json/update_web.json' };
    constructor(@Inject(PLATFORM_ID) private platformId: object) {}
    isBrowser!: boolean;
    ngOnInit(): void {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    onCancel() {
        this.data.close();
    }
}
