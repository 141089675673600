import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
        // Handle special characters and malformed URIs
        try {
            return this.defaultUrlSerializer.parse(url);
        } catch (error) {
            // Redirect to "Not Found" page or handle the error as desired
            return this.defaultUrlSerializer.parse('/not-found');
        }
    }

    serialize(tree: UrlTree): string {
        // Serialize the URL tree
        return this.defaultUrlSerializer.serialize(tree);
    }
}
