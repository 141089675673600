<div class="modal-header border-0 model-close-btn">
    <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
</div>
<div class="modal-body text-center pt-0">
    <!-- Logout -->
    <ng-container *ngIf="modal?.type === MODAL_TYPE.LOG_OUT">
        <img [src]="CDN_URL + 'coinInfo/icon/' + 'logout-img.png'" class="icon-width-nav" alt="logout" />
        <h5 id="exampleModalLabel">{{ 'LOGOUT.TITLE' | translate }}</h5>
        <p class="mt-2">{{ 'LOGOUT.TEXT' | translate }}</p>
        <div>
            <button type="submit" class="btn-twice-outline" (click)="onLogout()">
                {{ 'LOGOUT.YES' | translate }}
            </button>
            <button type="submit" class="btn-default btn-secondary ms-2" (click)="onCancel()">
                {{ 'LOGOUT.NO' | translate }}
            </button>
        </div>
    </ng-container>
</div>
