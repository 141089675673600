import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-wallet-kyc-modal',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './wallet-kyc-modal.component.html',
    styleUrls: ['./wallet-kyc-modal.component.scss']
})
export class WalletKycModalComponent {
    CDN = environment.CDN_URL;
    @Input() data;

    onSetup() {
        this.data.close({ status: true });
    }

    onCancel() {
        this.data.close({ status: false });
    }
}
