import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('toastElement') toastElement!: ElementRef<HTMLDivElement>;
    CDN_URL = environment.CDN_URL;
    IXFI_URL = environment.IXFI_EXCHANGE;
    isWebView!: boolean;
    cookieConsent!: boolean;
    isMobileDevice!: boolean;
    showDownload!: boolean;
    cookiesImg!: HTMLElement | null;
    interval!: NodeJS.Timeout;
    scrollListener!: () => void;

    constructor(
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
            if (!this.isWebView) {
                this.isMobileDevice = this.detectMob();
                this.cookieConsent = JSON.parse(this.cookieService.get(STORAGE_KEY.COOKIE_CONSENT) || 'false');
            }

            if (this.cookieConsent) {
                this.showAppDownload();
            }
            this.scrollListener = () => {
                const theta = (window.scrollY / 100) % Math.PI;
                const divData = this.cookiesImg as HTMLElement;
                if (divData) divData.style.transform = 'rotate(' + theta + 'rad)';
            };
            window.addEventListener('scroll', this.scrollListener);
        }
    }
    ngAfterViewInit() {
        this.cookiesImg = document.getElementById('cookies_img');
    }
    showAppDownload() {
        this.showDownload = JSON.parse(this.cookieService.get(STORAGE_KEY.DOWNLOAD_APP) || 'true');
    }

    setCookie() {
        this.cookieConsent = true;
        this.cookieService.set(STORAGE_KEY.COOKIE_CONSENT, JSON.stringify(true), undefined, '/', environment.DOMAIN);
        this.showAppDownload();
    }

    detectMob() {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    onClose() {
        this.toastElement.nativeElement.classList.remove('open-animation');
        this.toastElement.nativeElement.classList.add('close-animation');
        if (isPlatformBrowser(this.platformId)) {
            this.interval = setTimeout(() => {
                this.showDownload = false;
                this.cookieService.set(
                    STORAGE_KEY.DOWNLOAD_APP,
                    JSON.stringify(false),
                    undefined,
                    '/',
                    environment.DOMAIN
                );
            }, 1000);
        }
    }

    downloadApp() {
        window.open('https://share.ixfi.com/downloadapp', 'blank');
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.removeEventListener('scroll', this.scrollListener);
            if (this.interval) clearInterval(this.interval);
        }
    }
}
