import { APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { TransferState, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, UrlSerializer } from '@angular/router';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleService } from './core/services/page-title.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { translateBrowserLoaderFactory } from './translate-browser.loader';
import { ChunkErrorHandler } from 'src/chunkError';
import { CustomUrlSerializer } from 'src/url-serilizer';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.LANGUAGE_URL, '.json');
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        BrowserAnimationsModule
    ],
    providers: [
        TransferState,
        { provide: APP_ID, useValue: 'serverApp' },
        { provide: ErrorHandler, useClass: ChunkErrorHandler },
        { provide: UrlSerializer, useClass: CustomUrlSerializer }
        // todo: leads to few issue like state of login and websocket connections
        // provideClientHydration()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private title: PageTitleService) {}
}
