import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/standalone/components/confirmation-modal/confirmation-modal.component';
import { MODAL_TYPE } from 'src/app/shared/models/modal-template.model';
import { ToastMessageService } from 'src/app/core/services/toast-message.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { isPlatformBrowser } from '@angular/common';

declare let fbq: Function;

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
    isLoggedIn!: boolean;

    subscriptions: Subscription[] = [];
    isBrowser!: boolean;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private ngbModalService: NgbModal,
        private toastService: ToastMessageService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        const authStatus$ = this.authService.loginStatus;

        /**
         * Subscribe to the AuthStatus Observable to get the Latest User Auth Status
         */
        const authSub = authStatus$.subscribe({
            next: response => {
                this.isLoggedIn = response;

                if (this.isLoggedIn) {
                    const preCheck$ = this.authService.getPreCheckLoyalty().subscribe();
                    this.subscriptions.push(preCheck$);
                }
            }
        });
        this.subscriptions.push(authSub);
    }

    onActivate(_event: any) {
        if (this.isBrowser) window.scroll(0, 0);
    }

    onSubscribe(email: string) {
        const payload = {
            email: email,
            flag: 1
        };
        const news$ = this.http
            .post<{ message: string; status: number; data: { is_subscribed: boolean } }>(
                `${environment.IXFI_API}auth/subscribe-newsletter`,
                payload
            )
            .subscribe({
                next: response => {
                    if (response.status === 200) {
                        this.toastService.showSuccessToast({
                            toastTitle: 'success',
                            toastMessage: response.message
                        });
                        if (!response.data.is_subscribed && environment.production) fbq('track', 'Lead');
                    } else {
                        this.toastService.showErrorToast({
                            toastTitle: 'error',
                            toastMessage: response.message
                        });
                    }
                }
            });
        this.subscriptions.push(news$);
    }

    onLogout() {
        this.ngbModalService.dismissAll();
        const modalRef = this.ngbModalService.open(ConfirmationModalComponent, {
            // windowClass: 'ng-modal-container',
            size: 'sm',
            animation: true,
            centered: true,
            backdrop: true
        });
        modalRef.componentInstance.modal = { modalRef, type: MODAL_TYPE.LOG_OUT, data: {} };
        modalRef.result.then(
            (result: any) => {
                // on resolve
                if (result?.value) {
                    this.authService.clearAuthData();
                    this.authService.redirectToPlatformUrl('auth/login', true);
                }
            },
            (_reason: any) => {
                // on reject
            }
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
