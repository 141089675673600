import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { IxfiLanguageService } from 'header';
import { Intercom } from 'ng-intercom-x';
import { API_RESPONSE_STATUS, STORAGE_KEY } from 'src/app/shared/models/enum.constants';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class IntercomService implements OnDestroy {
    subscriptions: Subscription[] = [];
    interval!: NodeJS.Timeout;

    constructor(
        private intercom: Intercom,
        private authService: AuthService,
        language: IxfiLanguageService,
        private userService: UserService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        const auth$ = authService.loginStatus.subscribe(status => {
            // delete the local data of intercom and restart only if it's not the webview from the app
            if (!status) {
                this.shutDownChat();
            }
            if (isPlatformBrowser(this.platformId)) {
                this.interval = setTimeout(() => {
                    if (!JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false')) {
                        this.updateUserHashForChat();
                    }
                }, 3000);
            }
        });
        this.subscriptions.push(auth$);

        const lang$ = language.languageObs.subscribe(lang => {
            this.updateIntercomConfig({
                language_override: lang.selectedLanguage.language_code.toLowerCase()
            });
        });
        this.subscriptions.push(lang$);
    }

    isRunningInWebView() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.includes('wv') || userAgent.includes('android') || userAgent.includes('ios');
    }

    initializeChat(user?) {
        if (isPlatformBrowser(this.platformId)) {
            this.intercom.boot({
                // app region
                api_base: 'https://api-iam.eu.intercom.io',
                app_id: environment.INTERCOM_APP_ID,
                // Supports all optional configuration.
                widget: {
                    activator: '#intercom'
                },
                ...user
            });
        }
    }

    updateIntercomConfig(config: any) {
        this.intercom.update(config);
    }

    shutDownChat() {
        this.intercom.shutdown();
    }

    showMessage() {
        this.intercom.showMessages();
    }

    open() {
        this.intercom.show();
    }

    updateUserHashForChat() {
        if (this.authService.isLoggedIn()) {
            const sub = this.userService.getIntercomUserDetail().subscribe(res => {
                if (res.status == API_RESPONSE_STATUS.success_code) {
                    this.initializeChat({
                        user_id: res.data.intercomId,
                        user_hash: res.data.intercomHash
                    });
                }
            });
            this.subscriptions.push(sub);
        } else {
            this.initializeChat();
        }
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
        this.subscriptions.forEach(item => item.unsubscribe());
    }
}
