import { EventStatus, IxfiRewardsVendor } from '../constants/types.constants';

export const VendorTicketSlug: { [key in IxfiRewardsVendor]: string } = {
    bpofgaming: 'bpof-ticket-bag-acdc26d51c7342bd',
    intotheblock: 'into-the-block',
    sense4fit: 'sense-4-fit',
    ixfi: '',
    BlockGames: 'blockgames',
    bober: 'bober',
    'ta-da': 'tada'
};
export interface Campaign {
    timer: Date;
    status: EventStatus;
    content: CampaignContent;
    vendorName: IxfiRewardsVendor;
}

export interface CampaignContent {
    detailPage: {
        overlay?: string;
        card: {
            entity: string;
            title: string;
            subtitle: string;
            smallBanner?: string; // used in winnerAnnounced
        };
        description: {
            heading: string;
            content: string[];
        };
        winnerAnnouncementInfo?: {
            heading: string;
            content: string[];
        };
    };
    landingPage: {
        banner: {
            callToAction: {
                displayText: string;
                navigateTo: string;
                isNotify?: boolean;
            };
        };
        ticketSection: {
            panelTitle: string;
            cardTitle: string;
            brandLogoUrl?: string;
            bgImageURL: string;
            ticketImageURL: string;
            description: Array<string>;
            timerLabel: string;
            callToAction: {
                displayText: string;
                navigateTo: string;
                isNotify?: boolean;
            };
            displayQuantity?: number;
        };
    };
}

export interface CampaignDateInfo {
    comingSoonTime: Date;
    endingTime: Date;
    winnerAnnouncementTime: Date;
}

export interface VendorContent {
    detailPage: {
        overlay: string;
    };
}
