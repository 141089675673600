import { Location, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { STORAGE_KEY } from 'src/app/shared/models/enum.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { WalletKycModalComponent } from 'src/app/standalone/components/wallet-kyc-modal/wallet-kyc-modal.component';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    refreshTokenTimeout: any;
    loginStatus = new BehaviorSubject<boolean>(this.isLoggedIn());
    subscription: Subscription[] = [];
    interval!: NodeJS.Timeout;

    private modalService = inject(NgbModal);
    private router = inject(Router);

    constructor(
        private apiService: ApiService,
        private location: Location,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    // *** Clear auth Data
    clearAuthData() {
        if (isPlatformBrowser(this.platformId)) {
            // clear local data
            this.clearData();

            // check if it's a webview route for app
            let isMobile = localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false';
            if (JSON.parse(isMobile)) {
                localStorage.removeItem(STORAGE_KEY.IS_MOBILE);
            }
        }
    }

    clearData() {
        this.cookieService.delete(STORAGE_KEY.IS_LOGGED_IN, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.TOKEN, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.TOKEN_EXPIRE_TIME, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.REFRESH_TOKEN, '/', environment.DOMAIN, true);

        this.cookieService.delete(STORAGE_KEY.USER, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.EMAIL, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.EMAIL_DATA, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.EMAIL_LOGIN, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.PHONE_DATA, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.FORM_DATA_VERIFY_OTP, '/', environment.DOMAIN, true);
        this.cookieService.delete(STORAGE_KEY.GEE_TEST, '/', environment.DOMAIN, true);
        this.loginStatus.next(false);
    }

    public isLoggedIn(): boolean {
        let status = false;
        let isMobile = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
        let token = this.cookieService.get(STORAGE_KEY.TOKEN) || '';
        let expiry: any = this.cookieService.get(STORAGE_KEY.EXPIRE_TIME) || '';
        if (token && (JSON.parse(isMobile) || new Date(expiry * 1000) >= new Date())) {
            status = true;
        }
        return status;
    }

    public startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        let exp = this.cookieService.get(STORAGE_KEY.EXPIRE_TIME) || '';
        // set a timeout to refresh the token a minute before it expires
        if (exp.length && isPlatformBrowser(this.platformId)) {
            const expires = new Date(parseInt(exp) * 1000);
            this.interval = setTimeout(
                () => {
                    const subs = this.refreshToken().subscribe(data => {
                        this.cookieService.set(STORAGE_KEY.TOKEN, data?.token);
                        this.cookieService.set(STORAGE_KEY.EXPIRE_TIME, data?.token_expire_time);
                    });
                    this.subscription.push(subs);
                },
                expires.getTime() - Date.now() - 60 * 1000
            );
        }
    }

    refreshToken() {
        return this.apiService.getPlatform(`auth/refresh-token`);
    }

    getRelease(params: any) {
        return this.apiService.getPlatform(`misc/release-info`, params);
    }

    getPreCheckLoyalty() {
        return this.apiService.getPlatform(`v1/reward-points/pre-check-loyalty`);
    }

    redirectToPlatformUrl(url: string, returnToCurrentPage?: boolean, newTab?: boolean) {
        if (!JSON.parse(this.cookieService.get(STORAGE_KEY.IS_MOBILE) || 'false')) {
            if (returnToCurrentPage) {
                this.cookieService.set(
                    STORAGE_KEY.RETURN_URL,
                    environment.IXFI_REWARDS + this.location.path(),
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
                this.cookieService.set(
                    STORAGE_KEY.IS_SUBDOMAIN,
                    JSON.stringify(true),
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
            }
            const APP_URL = environment.IXFI_EXCHANGE + url;
            if (!newTab) {
                window.location.href = APP_URL;
            } else {
                this.redirectToNewTab(APP_URL);
            }
        }
    }

    redirectToNewTab(url: string) {
        window.open(url, '_blank', 'noopener');
    }

    openWalletKYCModal() {
        this.modalService.dismissAll(); // temp
        const modalRef = this.modalService.open(WalletKycModalComponent, {
            centered: true,
            scrollable: true,
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.data = modalRef;

        modalRef.componentInstance.isTrusted = false;

        modalRef.result
            .then(result => {
                if (result.status) this.redirectToPlatformUrl('profile/due-diligence/kyc');
                else this.router.navigate(['/']);
            })
            .catch(err => console.log(err));
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
        this.subscription.forEach(item => item?.unsubscribe());
    }
}
