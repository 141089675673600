<div class="toast fade show border-0">
    <div class="toast-header">
        <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
    </div>
    <div class="position-relative py-2 ps-1" [ngClass]="toastClass">
        <div class="d-flex align-items-center">
            <span class="me-2 toast-message-lottie">
                <ng-lottie [options]="toastImage" width="100%" height="100%"></ng-lottie>
            </span>
            <span>
                {{ toastMessage }}
            </span>
        </div>
    </div>
</div>
