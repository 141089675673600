import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* IXFI Library Modules */
import { FooterModule } from 'footer';
import { HeaderModule } from 'header';

/* Components */
import { ShellComponent } from './components/shell/shell.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CookiePopupComponent } from './components/cookie-popup/cookie-popup.component';

/* 3rd Party Packages */
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
/* Import Player for AOT Compilation - Using a minified version */
import player from 'lottie-web/build/player/lottie_light';
export function playerFactory() {
    return player;
}

import { ToastrModule } from 'ngx-toastr';

/* Translator Module */
import { environment } from '../../environments/environment';
import { ShellMobileComponent } from './components/shell-mobile/shell-mobile.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { IntercomModule } from 'ng-intercom-x';
import { OfflineComponent } from './components/offline/offline.component';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './services/interceptors/http-error.interceptor';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';

// Specify Source for Translations

@NgModule({
    declarations: [ShellComponent, LoaderComponent, OfflineComponent, CookiePopupComponent, ShellMobileComponent],
    imports: [
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbDropdownModule,
        TranslateModule,
        CommonModule,
        NgbModule,
        ToastrModule.forRoot(),
        FooterModule.forRoot(environment),
        HeaderModule.forRoot(environment),
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_APP_ID, // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    breaks: true,
                    pedantic: false,
                    smartLists: true,
                    smartypants: false
                }
            }
        })
    ],
    exports: [LoaderComponent, LottieModule, LoaderComponent, OfflineComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    ]
})
export class CoreModule {}
