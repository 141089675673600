import { environment } from '../../../../environments/environment';
import { CampaignContent, VendorTicketSlug } from '../models/campaign.model';
import { EventStatus } from './types.constants';

export const BPOFGAMING_CAMPAIGN: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Upcoming Campaign for BPOF',
                subtitle: 'This is the subtitle for BPOF',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Description',
                content: [
                    'ixfi partnered with BPOF Gaming to offer Racing NFTs that can be used in Blockchain games to compete against other NFT holders. Users can customize their cand and racer NFT, increasing their chance to win even greater prizes in Road and Galaxy races. The process is simple: You need to sign up or log in to your ixfi account and earn ixfi Points. How? By completing the simple tasks described below. You can use earned ixfi Points to purchase multiple Airdrop tickets, increasing your chances of winning.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            },
            ticketSection: {
                panelTitle: '',
                cardTitle: '',
                bgImageURL: '',
                ticketImageURL: '',
                description: [''],
                timerLabel: '',
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            }
        }
    },
    onGoing: {
        detailPage: {
            card: {
                title: 'Ongoing Campaign for BPOF',
                subtitle: 'This is the subtitle for BPOF',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Description',
                content: [
                    'ixfi partnered with BPOF Gaming to offer Racing NFTs that can be used in Blockchain games to compete against other NFT holders. Users can customize their cand and racer NFT, increasing their chance to win even greater prizes in Road and Galaxy races. The process is simple: You need to sign up or log in to your ixfi account and earn ixfi Points. How? By completing the simple tasks described below. You can use earned ixfi Points to purchase multiple Airdrop tickets, increasing your chances of winning.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            },
            ticketSection: {
                panelTitle: '',
                cardTitle: '',
                bgImageURL: '',
                ticketImageURL: '',
                description: [''],
                timerLabel: '',
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            }
        }
    },
    saleEnded: {
        detailPage: {
            card: {
                title: 'Congratulations to every winner',
                subtitle:
                    'Thank you for participating in ixfi’s NFT Airdrop. BPOF Fidelity cards are sent to the provided Elrond Blockchain address.',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Description',
                content: [
                    'Your participation and interest are great signs for us to improve and conduct further Airdrop-style events in the near future. Follow our socials and subscribe to the newsletter to be the first that finds out about upcoming events!',
                    'Since the BPOF NFT Airdrop has ended, the tickets you purchased using ixfi Points have entered a pool. ixfi uses a provably-fair algorithm to determine the 350 Airdrop winners.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            },
            ticketSection: {
                panelTitle: '',
                cardTitle: '',
                bgImageURL: '',
                ticketImageURL: '',
                description: [''],
                timerLabel: '',
                callToAction: {
                    displayText: '',
                    navigateTo: ''
                }
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            card: {
                title: 'Congratulations to every winner',
                subtitle:
                    'Thank you for participating in ixfi’s NFT Airdrop. BPOF Fidelity cards are sent to the provided Elrond Blockchain address.',
                entity: 'Airdrop Tickets',
                smallBanner: environment.CDN_URL + 'reward/bpof/nft-banner-winner.png'
            },
            description: {
                heading: 'Description',
                content: [
                    'ixfi partnered with BPOF Gaming to offer Racing NFTs that can be used in Blockchain games to compete against other NFT holders. Users can customize their cand and racer NFT, increasing their chance to win even greater prizes in Road and Galaxy races. The process is simple: You need to sign up or log in to your ixfi account and earn ixfi Points. How? By completing the simple tasks described below. You can use earned ixfi Points to purchase multiple Airdrop tickets, increasing your chances of winning.'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize and distribution details',
                content: [
                    'BPOF NFTs are sent on Maiar App to the provided herotags, using the Elrond Network.',
                    'Winners can use the BPOF Fidelity NFTs however they prefer: either by participating in the custom road and galaxy races and upcoming features of BPOF - or by selling them on the marketplace.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'See the Winners',
                    navigateTo: VendorTicketSlug.bpofgaming
                }
            },
            ticketSection: {
                panelTitle: 'Winners of ixfi’s NFT Airdrop are here!',
                cardTitle: 'View Winners Details',
                bgImageURL: '',
                brandLogoUrl: environment.CDN_URL + 'bpof/ixfi-bpof-logo.svg',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'We’re thrilled to announce the 350 winners that received one of the BPOF Fidelity Card NFTs!'
                ],
                timerLabel: '',
                callToAction: {
                    displayText: 'See the Winners',
                    navigateTo: VendorTicketSlug.bpofgaming
                }
            }
        }
    }
};

export const INTOTHEBLOCK_CAMPAIGN: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Purchase Airdrop Tickets Now and Increase Your Chances of Winning',
                subtitle: 'Get Your Airdrop Tickets for Only 20 ixfi points',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Enjoy Crypto Intelligence & Exceptional Market Insights',
                content: [
                    'IntotheBlock made it easier for crypto users to unlock the potential of their digital assets. Airdrop tickets give users a chance to win exclusive premium subscriptions and discounts.',
                    "You can use ixfi points to claim your tickets - making IntoTheBlock's services more accessible and rewarding. Join us today and take advantage of IntoTheBlock's premium tools and exceptional market indicators."
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            },
            ticketSection: {
                timerLabel: 'Tickets sale starts in',
                panelTitle: 'Conquer the crypto space with leading intelligence tools',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'You will instantly be selected to participate in ixfi’s Airdrop for a chance to win an ITB yearly premium subscription.',
                    'Requirements: All mandatory tasks must be completed.'
                ],
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            }
        }
    },
    onGoing: {
        detailPage: {
            card: {
                title: 'Purchase Airdrop Tickets Now and Increase Your Chances of Winning',
                subtitle: 'Get Your Airdrop Tickets for Only 20 ixfi points',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Enjoy Crypto Intelligence & Exceptional Market Insights',
                content: [
                    'IntotheBlock made it easier for crypto users to unlock the potential of their digital assets. Airdrop tickets give users a chance to win exclusive premium subscriptions and discounts.',
                    "You can use ixfi points to claim your tickets - making IntoTheBlock's services more accessible and rewarding. Join us today and take advantage of IntoTheBlock's premium tools and exceptional market indicators."
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            },
            ticketSection: {
                timerLabel: 'Tickets sale ends in',
                panelTitle: 'Conquer the crypto space with leading intelligence tools',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'You will instantly be selected to participate in ixfi’s Airdrop for a chance to win an ITB yearly premium subscription.',
                    'Requirements: All mandatory tasks must be completed.'
                ],
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            }
        }
    },
    saleEnded: {
        detailPage: {
            card: {
                title: 'Congratulations to every winner',
                subtitle:
                    'A big thank you to everyone who participated in our Airdrop. IntoTheBlock yearly subscriptions will be given to winners via codes sent to the email used at registration.',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'The Last Premium Investing Tools you will ever need',
                content: [
                    'We are thrilled to have had such a successful Airdrop and to offer IntoTheBlock yearly premium subscriptions to a deserving group of users.',
                    'These subscriptions will provide an access to exclusive content, advanced features, and personalized support. We are excited to be able to to offer these prizes to the winners and look forward to seeing them take advantage of all that IntoTheBlock has to offer.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            },
            ticketSection: {
                timerLabel: 'Winner to be announced in',
                panelTitle: 'Conquer the crypto space with leading intelligence tools',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'You will instantly be selected to participate in ixfi’s Airdrop for a chance to win an ITB yearly premium subscription.',
                    'Requirements: All mandatory tasks must be completed.'
                ],
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            card: {
                title: 'Claim your Airdrop Tickets now',
                subtitle: 'Price: 20 ixfi points for one Airdrop Ticket',
                entity: 'Airdrop Tickets',
                smallBanner: environment.CDN_URL + 'itb/itb-winner-banner.png'
            },
            description: {
                heading: 'Enjoy Crypto Intelligence & Exceptional Market Insights',
                content: [
                    'IntotheBlock made it easier for crypto users to unlock the potential of their digital assets. Airdrop tickets give users a chance to win exclusive premium subscriptions and discounts.',
                    "You can use ixfi points to claim your tickets - making IntoTheBlock's services more accessible and rewarding. Join us today and take advantage of IntoTheBlock's premium tools and exceptional market indicators."
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize and distribution details',
                content: [
                    'We are thrilled to have had such a successful Airdrop and to offer IntoTheBlock yearly premium subscriptions to a deserving group of users.',
                    'These subscriptions will provide an access to exclusive content, advanced features, and personalized support. We are excited to be able to to offer these prizes to the winners and look forward to seeing them take advantage of all that IntoTheBlock has to offer.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'See the Winners',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            },
            ticketSection: {
                timerLabel: 'Winner to be announced in',
                panelTitle: 'Conquer the crypto space with leading intelligence tools',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'You will instantly be selected to participate in ixfi’s Airdrop for a chance to win an ITB yearly premium subscription.',
                    'Requirements: All mandatory tasks must be completed.'
                ],
                callToAction: {
                    displayText: 'See the Winners',
                    navigateTo: VendorTicketSlug.intotheblock
                }
            }
        }
    }
};

export const SENSE_4_FIT: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Purchase Airdrop Tickets Now and Increase Your Chances of Winning',
                subtitle: 'Get Your Airdrop Tickets for Only 20 ixfi points',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Enjoy Crypto Intelligence & Exceptional Market Insights',
                content: [
                    'IntotheBlock made it easier for crypto users to unlock the potential of their digital assets. Airdrop tickets give users a chance to win exclusive premium subscriptions and discounts.',
                    "You can use ixfi points to claim your tickets - making IntoTheBlock's services more accessible and rewarding. Join us today and take advantage of IntoTheBlock's premium tools and exceptional market indicators."
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            },
            ticketSection: {
                timerLabel: 'Ticket sale starts in',
                panelTitle: 'Participate in our Airdrop & Win Sense4FIT NFTs',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'You will be able to purchase Airdrop tickets once the Airdrop starts.',
                    'Multiple tickets increase your chances of winning.'
                ],
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            }
        }
    },
    onGoing: {
        detailPage: {
            card: {
                title: 'Purchase Airdrop tickets now',
                subtitle: 'Price: 20 ixfi points / ticket',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Win NFTs worth over $8,000 with ixfi & Sense4FIT',
                content: [
                    'Participate in our exclusive Airdrop to win one of the 80 Sense4FIT NFTs. By purchasing multiple Airdrop tickets, you significantly increase your chances of winning.',
                    'Don’t miss out on this great opportunity to build your NFT portfolio while staying in shape with Sense4FIT workouts and community.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale ends in',
                panelTitle: 'The Airdrop ticket sale has started. Get your tickets now!',
                cardTitle: 'Purchase Airdrop Tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Make sure you complete all the Mandatory Tasks to be eligible.',
                    'You can increase your chances of winning by purchasing multiple tickets.'
                ],
                callToAction: {
                    displayText: 'Participate in Giveaway',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            }
        }
    },
    saleEnded: {
        detailPage: {
            card: {
                title: 'Congratulations to every winner',
                subtitle: 'A big thank you to everyone who participated in our Airdrop.',
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'Win NFTs worth over $8,000 with ixfi & Sense4FIT',
                content: [
                    'Participate in our exclusive Airdrop to win one of the 80 Sense4FIT NFTs. By purchasing multiple Airdrop tickets, you significantly increase your chances of winning.',
                    'Don’t miss out on this great opportunity to build your NFT portfolio while staying in shape with Sense4FIT workouts and community.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'More Details',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale has closed',
                panelTitle: 'Airdrop closed. Winners will be announced soon.',
                cardTitle: 'Thank you for participating',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The Airdrop ticket sale has ended. We will announce the winners very soon.',
                    'In the meantime, follow our Telegram channel for updates.'
                ],
                callToAction: {
                    displayText: 'Buy Ticket',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            card: {
                title: 'Congratulations to every winner',
                subtitle:
                    'We want to express our gratitude to everyone who participated in the Airdrop. The Sense4FIT NFTs will be sent directly to the provided MultiversX wallet.',
                entity: 'Tickets',
                smallBanner: environment.CDN_URL + 'sense4fit/s4f-winner-banner.png'
            },
            description: {
                heading: 'Bring your active lifestyle to another level',
                content: [
                    'Using Sense4FIT’s mobile application and the “FIT to EARN” program, you can benefit from fitness, nutrition, personal development, and mindfulness classes.',
                    'The social and gamified elements help people work out and set lifestyle objectives while keeping the community together.'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize details',
                content: [
                    'We are more than happy to announce the winners of our last Airdrop, made in collaboration with Sense4FIT.',
                    'The NFTs work as a membership pass to Sense4FIT’s community. Using the NFT, you can be part of an active group of individuals prioritizing a healthy lifestyle and workouts.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale has closed',
                panelTitle: 'Airdrop closed. Congratulations to every winner!',
                cardTitle: 'Thank you for participating',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The Airdrop ticket sale has been closed.',
                    'Check the list of winners and use your new NFT to achieve your fitness goals with Sense4FIT.'
                ],
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.sense4fit
                }
            }
        }
    }
};

export const BLOCKGAMES: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Get your Airdrop tickets now',
                subtitle: 'Each ticket costs 20 ixfi Points. Earn ixfi points by completing the mandatory tasks.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Win exclusive rewards with ixfi and BOBER',
                content: [
                    'Explore our BOBER Airdrop and secure a chance to win one of the five NFTs, each valued at 1 ETH, contributing to a total prize pool worth over $16,000.',
                    'This Airdrop offers a unique opportunity to expand your digital collection without risk exposure and engage deeper within the BlockGames ecosystem.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            },
            ticketSection: {
                timerLabel: 'Ticket sale countdown',
                panelTitle: 'Boost your win rate: Buy Airdrop tickets now!',
                cardTitle: 'Get your Airdrop tickets',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Secure more tickets with your ixfi Points to elevate your chances of winning a BlockGames Dice NFT!',
                    'Note: Completion of all mandatory tasks is required for eligibility.'
                ],
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                },
                displayQuantity: 1
            }
        }
    },
    onGoing: {
        detailPage: {
            overlay: 'Use ixfi Points to participate in latest Airdrop.<br/>Prizes worth over 4 ETH!',
            card: {
                title: 'Get your Airdrop tickets now',
                subtitle: 'Each ticket costs 20 ixfi Points. Earn ixfi points by completing the mandatory tasks.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Grab exclusive NFT rewards with ixfi and BlockGames Dice',
                content: [
                    'Explore our BlockGames Dice Airdrop and secure a chance to collect one of the five NFTs, each valued at 0.8 ETH, contributing to a total prize pool worth over 4 ETH.',
                    'This Airdrop offers a unique opportunity to expand your digital collection without risk exposure and engage deeper within the BlockGames ecosystem.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate',
                    navigateTo: VendorTicketSlug.BlockGames
                }
            },
            ticketSection: {
                timerLabel: 'Tickets’ availability',
                panelTitle: "It's your big chance: Purchase Airdrop tickets now!",
                cardTitle: 'More tickets = Bigger chances',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Use your ixfi Points to purchase Airdrop tickets, increasing your opportunity to collect a BlockGames Dice NFT worth 0.8 ETH. Remember, completing all mandatory tasks is required for participation.'
                ],
                callToAction: {
                    displayText: 'Participate',
                    navigateTo: VendorTicketSlug.BlockGames
                },
                displayQuantity: 1
            }
        }
    },
    saleEnded: {
        detailPage: {
            overlay: 'The next step for ixfi’s Airdrop is here:<br/>Winners to be announced soon',
            card: {
                title: 'Ticket sale has ended',
                subtitle:
                    "As we conclude the ticket sale phase, we're preparing to unveil the next step in our Airdrop journey with BlockGames.",
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'A short overview of our Airdrop',
                content: [
                    "The Airdrop made in partnership with BlockGames, which took place from March 19 to April 2, concluded with remarkable engagement from the community. With {{count}} tickets sold, the enthusiasm has been palpable, illustrating our users' vibrant participation and support.",
                    'This chapter showcased the active involvement of both communities and laid the groundwork for future Airdrops. Thank you to everyone who participated. Your implication fuels our drive to bring engaging opportunities to the ixfi platform'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'More Details',
                    navigateTo: VendorTicketSlug.BlockGames
                }
            },
            ticketSection: {
                timerLabel: 'Winners announcement',
                panelTitle: 'Airdrop ended: Winners to be revealed soon',
                cardTitle: 'Thank you for joining us',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The ticket sale for the BlockGames Airdrop has ended.',
                    'Winners will be announced soon. Follow our Twitter and turn on notifications for updates.'
                ],
                callToAction: {
                    displayText: 'Buy Ticket',
                    navigateTo: VendorTicketSlug.BlockGames
                },
                displayQuantity: 1
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            overlay: 'Use your ixfi points to participate  <br/>in our newest NFT Airdrop',
            card: {
                title: 'Congratulations to every winner',
                subtitle: 'We want to express our gratitude to everyone who participated in the Airdrop.',
                entity: 'Tickets',
                smallBanner: environment.CDN_URL + 'reward/blockGame/winner-banner.png'
            },
            description: {
                heading: 'Grab exclusive NFT rewards with ixfi and BlockGames Dice',
                content: [
                    'Explore our BlockGames Dice Airdrop and secure a chance to collect one of the five NFTs, each valued at 0.8 ETH, contributing to a total prize pool worth over 4 ETH.',
                    'This Airdrop offers a unique opportunity to expand your digital collection without risk exposure and engage deeper within the BlockGames ecosystem.'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize details',
                content: [
                    'We are more than happy to announce the winners of our last Airdrop, made in collaboration with BlockGames.',
                    'The NFTs work as a membership pass to BlockGames’ community. Using the NFT, you can be part of an active group of individuals prioritizing a healthy lifestyle and workouts.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.BlockGames
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale has closed',
                panelTitle: 'Airdrop closed. Congratulations to every winner!',
                cardTitle: 'Thank you for participating',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The Airdrop ticket sale has been closed.',
                    'Check the list of winners and use your new NFT to achieve your fitness goals with BlockGames.'
                ],
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.BlockGames
                },
                displayQuantity: 1
            }
        }
    }
};
export const BOBER: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Get your Airdrop tickets now',
                subtitle: 'Each ticket costs 20 ixfi Points. Earn ixfi points by completing the mandatory tasks.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Win exclusive rewards with ixfi and BOBER',
                content: [
                    'Explore our BOBER Airdrop and secure a chance to win $BOBER.',
                    'This Airdrop offers a unique opportunity to expand your digital collection without risk exposure and engage deeper within the BlockGames ecosystem.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            },
            ticketSection: {
                timerLabel: 'Ticket sale starts in:',
                panelTitle: 'more Airdrop tickets = more chances to BOBER 🦫',
                cardTitle: 'The BOBER way:',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Beavers not only build dams but also secure more Airdrop tickets, increasing their chances of winning. Press the yellow button below to be notified when the ticket sale starts.'
                ],
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                },
                displayQuantity: 1
            }
        }
    },
    onGoing: {
        detailPage: {
            overlay: 'Buy Airdrop tickets for a chance to win <br/>$100* worth of BOBER',
            card: {
                title: 'Total prize pool: $15.000* in BOBER',
                subtitle:
                    'By buying more tickets, you increase your chances of winning. A total prize pool of 28,301,850 BOBER is up for grabs.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'More details',
                content: [
                    'The cost of one Airdrop ticket is 20 IXFI Points. You can purchase multiple tickets, each increasing your chances of winning a reward of 188,679 BOBER, from the total prize pool of 28,301,850 BOBER.',
                    '*Calculated at the price of 1 BOBER on April 16th ($0,00053)'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate',
                    navigateTo: VendorTicketSlug.bober
                }
            },
            ticketSection: {
                timerLabel: 'Tickets’ availability',
                panelTitle: '$15,000* worth of BOBER prizes to be Airdroped',
                cardTitle: 'Purchase tickets now',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Purchase as many Airdrop tickets as possible to increase your chances of winning one of the 150 BOBER bags, each worth $100*',
                    '*Calculated at the price of 1 BOBER on April 16th ($0,00053).'
                ],
                callToAction: {
                    displayText: 'Participate',
                    navigateTo: VendorTicketSlug.bober
                },
                displayQuantity: 1
            }
        }
    },
    saleEnded: {
        detailPage: {
            overlay: 'The BOBER Airdrop ticket sale has ended',
            card: {
                title: '$15,000* in BOBER will be distributed to 150 winners',
                subtitle:
                    "If you bought tickets for the BOBER Airdrop, there’s nothing left to do now. Wait for the winners' announcement to see if you’re lucky.",
                entity: 'Airdrop Tickets'
            },
            description: {
                heading: 'A short overview of our Airdrop',
                content: [
                    "The Airdrop made in partnership with BOBER, which took place from April 17 2024 to May 3 2024, concluded with remarkable engagement from the community. With {{count}} tickets sold, the enthusiasm has been palpable, illustrating our users' vibrant participation and support.",
                    'This chapter showcased the active involvement of both communities and laid the groundwork for future Airdrops. Thank you to everyone who participated. Your implication fuels our drive to bring engaging opportunities to the ixfi platform'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'More Details',
                    navigateTo: VendorTicketSlug.bober
                }
            },
            ticketSection: {
                timerLabel: 'Winners announcement',
                panelTitle: 'The Airdrop is closed. Wait for the winners’ announcement.',
                cardTitle: 'Ticket sale ended',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'If you bought at least one Airdrop ticket, you can be one of the 150 winners. Each winner receives 188,679 BOBER.',
                    'The announcement will be posted on our X (Twitter) on 3rd May.'
                ],
                callToAction: {
                    displayText: 'More Details',
                    navigateTo: VendorTicketSlug.bober
                },
                displayQuantity: 1
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            overlay: 'The $15,000* BOBER Airdrop is over.<br/>Congratulations to every winner',
            card: {
                title: '28,301,850 BOBER to be distributed',
                subtitle:
                    'Thank you for your participation. The prize pool, consisting of $15,000* worth of BOBER, will be distributed to the 150 winners, ensuring each receives $100* worth of BOBER.',
                entity: 'Tickets',
                smallBanner: environment.CDN_URL + 'reward/bober/bober-winner-banner.png'
            },
            description: {
                heading: 'Dear ixfi users, this one’s for you',
                content: [
                    'ixfi’s commitment to rewarding its community has been proven once again. The BOBER Airdrop was an astounding success, and the dedicated users who completed the tasks and won a scratch card were rewarded.'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize details',
                content: [
                    'Each Airdrop winner will receive 188,679 BOBER on the MultiversX address shared with us. The winners should be able to see their prizes in the xPortal app.',
                    'There are 150 winners, each receiving $100* worth of BOBER from the total prize pool of $15,000* worth of BOBER.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.bober
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale has closed',
                panelTitle: 'The Airdrop is closed. Check if you’re one of the winners.',
                cardTitle: '150 winners revealed',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The BOBER Airdrop was a success and the $15.000* prize pool will be distributed as  $100* rewards worth of BOBER.'
                ],
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug.bober
                },
                displayQuantity: 1
            }
        }
    }
};
export const TA_DA: { [key in EventStatus]: CampaignContent } = {
    comingSoon: {
        detailPage: {
            card: {
                title: 'Get your Airdrop tickets now',
                subtitle: 'Each ticket costs 20 ixfi Points. Earn ixfi points by completing the mandatory tasks.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Win exclusive rewards with ixfi and BOBER',
                content: [
                    'Explore our BOBER Airdrop and secure a chance to win $BOBER.',
                    'This Airdrop offers a unique opportunity to expand your digital collection without risk exposure and engage deeper within the BlockGames ecosystem.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                }
            },
            ticketSection: {
                timerLabel: 'Ticket sale starts in:',
                panelTitle: 'Participate in the Airdrop to win $100 worth of TADA',
                cardTitle: 'What’s the next step?',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The Airdrop has launched. Simply click Notify me and wait for the ticket sale to start. In the meantime, complete tasks for IXFI Points and use them to purchase Airdrop tickets.'
                ],
                callToAction: {
                    displayText: 'Notify me',
                    navigateTo: '',
                    isNotify: true
                },
                displayQuantity: 1
            }
        }
    },
    onGoing: {
        detailPage: {
            overlay: 'Buy Airdrop tickets for a chance to win <b/>$100 worth of TADA',
            card: {
                title: 'Total prize pool: $15.000 in TADA',
                subtitle:
                    'Purchasing more tickets increases your chances of winning. A total prize pool of 104,550 TADA will be awarded.',
                entity: 'Airdrop tickets'
            },
            description: {
                heading: 'Further details',
                content: [
                    'The cost of one Airdrop ticket is 20 IXFI Points. You can purchase multiple tickets, each increasing your chances of winning a reward of 697 TADA from the total prize pool of 104,550 TADA.',
                    'Note that the prizes are calculated at the price of 1 TADA on May 8th ($0.1435).'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Participate now',
                    navigateTo: VendorTicketSlug['ta-da']
                }
            },
            ticketSection: {
                timerLabel: 'Tickets’ availability',
                panelTitle: 'Airdrop ticket sale has started. Get your tickets now!',
                cardTitle: 'The time is limited',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'Ticket sale is open until 24 May.',
                    'Make sure you complete the mandatory tasks and purchase Airdrop tickets using ixfi Points before the time runs out.'
                ],
                callToAction: {
                    displayText: 'Participate now',
                    navigateTo: VendorTicketSlug['ta-da']
                },
                displayQuantity: 1
            }
        }
    },
    saleEnded: {
        detailPage: {
            overlay: 'The $15,000 TADA Airdrop is over.<br/>Winners to be announced soon',
            card: {
                title: '104,550 TADA will be distributed',
                subtitle:
                    'Thank you for your participation. The prize pool, consisting of $15,000 worth of TADA, will be distributed to 150 winners, ensuring each receives $100 worth of TADA',
                entity: 'Tickets',
                smallBanner: environment.CDN_URL + 'reward/ta-da/tada-winner-banner.png'
            },
            description: {
                heading: 'Prize details',
                content: [
                    'The winners will be announced on May 27th. Each Airdrop winner will receive 697 TADA on the MultiversX address shared with us'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize details',
                content: [
                    'Each Airdrop winner will receive 188,679 BOBER on the MultiversX address shared with us. The winners should be able to see their prizes in the xPortal app.',
                    'There are 150 winners, each receiving $100* worth of BOBER from the total prize pool of $15,000* worth of BOBER.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'See more',
                    navigateTo: VendorTicketSlug['ta-da']
                }
            },
            ticketSection: {
                timerLabel: 'Winners announcement',
                panelTitle: 'The Airdrop has ended. Winners will be announced soon.',
                cardTitle: 'Ticket sale is closed',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'If you bought at least one Airdrop ticket, you can be one of the 150 winners to share $15,000 in rewards.',
                    'Follow our X (Twitter) for the winners’ announcement'
                ],
                callToAction: {
                    displayText: 'See more',
                    navigateTo: VendorTicketSlug['ta-da']
                },
                displayQuantity: 1
            }
        }
    },
    winnerAnnounced: {
        detailPage: {
            overlay: 'The $15,000 TADA Airdrop is over.<br/>Congratulations to every winner',
            card: {
                title: '104,550 TADA to be distributed',
                subtitle:
                    'Thank you for your participation. The prize pool, consisting of $15,000 worth of TADA, will be distributed to the 150 winners, ensuring each receives $100 worth of TADA.',
                entity: 'Tickets',
                smallBanner: environment.CDN_URL + 'reward/ta-da/tada-winner-banner.png'
            },
            description: {
                heading: 'Stay connected',
                content: [
                    'Join our communities on Telegram and Discord. Engage with other members, get real-time updates, and never miss out on future Airdrops and special events.'
                ]
            },
            winnerAnnouncementInfo: {
                heading: 'Prize details',
                content: [
                    'Each Airdrop winner will receive 697 TADA on the MultiversX address shared with us.',
                    'There are 150 winners, each receiving $100 worth of TADA from the total prize pool of $15,000 worth of TADA.'
                ]
            }
        },
        landingPage: {
            banner: {
                callToAction: {
                    displayText: 'Winners list',
                    navigateTo: VendorTicketSlug['ta-da']
                }
            },
            ticketSection: {
                timerLabel: 'The ticket sale has closed',
                panelTitle: '$15,000 worth of TADA tokens to be Airdroped',
                cardTitle: '150 winners revealed',
                bgImageURL: '',
                ticketImageURL: environment.CDN_URL + 'bpof/ixfi-ticket.png',
                description: [
                    'The TADA Airdrop was a success and the $15,000 prize pool will be distributed as  $100 rewards worth of TADA.'
                ],
                callToAction: {
                    displayText: "Winners' list",
                    navigateTo: VendorTicketSlug['ta-da']
                },
                displayQuantity: 1
            }
        }
    }
};
