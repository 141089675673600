import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-toast-popup',
    templateUrl: './toast-popup.component.html',
    styleUrls: ['./toast-popup.component.scss'],
    standalone: true,
    imports: [LottieModule, CommonModule]
})
export class ToastPopupComponent extends Toast implements OnInit {
    lottieOptions: any = {
        loop: false,
        path: ''
    };

    @Input() toastType!: string;
    toastMessage: string | null | undefined;
    toastTitle: string | undefined;
    constructor(protected toastr: ToastrService, public override toastPackage: ToastPackage) {
        super(toastr, toastPackage);
    }

    ngOnInit(): void {
        this.toastMessage = this.toastPackage.message;
        this.toastTitle = this.toastPackage.title;
    }

    // Returns the toast body class based on toast type
    get toastClass(): string {
        let assignedClass: string = '';
        if (this.toastType.toLowerCase() === 'success') {
            assignedClass = 'toast-body-success';
        } else if (this.toastType.toLowerCase() === 'warning') {
            assignedClass = 'toast-body-wrong';
        } else if (this.toastType.toLowerCase() === 'error') {
            assignedClass = 'toast-body-unsuccess';
        }
        return assignedClass;
    }

    // Returns the src attribute of the toast <img> element
    get toastImage(): AnimationOptions<'svg'> | null {
        if (this.toastType.toLowerCase() === 'success') {
            this.lottieOptions.path = '/assets/lottie-success.json';
        } else if (this.toastType.toLowerCase() === 'warning') {
            this.lottieOptions.path = '/assets/lottie-warning.json';
        } else if (this.toastType.toLowerCase() === 'error') {
            this.lottieOptions.path = '/assets/lottie-error.json';
        }
        return this.lottieOptions;
    }
}
