import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { IxfiThemeService } from 'header';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
    public STATIC_S3_ASSETS_URL = environment.CDN_URL;
    isDarkMode: boolean = false;
    subscription!: Subscription;
    options: AnimationOptions = {
        path: `${environment.CDN_URL}app-loader.json`
    };
    isBrowser!: boolean;
    CDN = environment.CDN_URL;

    private themeService = inject(IxfiThemeService);

    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    ngOnInit(): void {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.subscription = this.themeService.themeObs.subscribe({
            next: isDarkModeEnabled => (this.isDarkMode = isDarkModeEnabled)
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
