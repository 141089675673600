import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { Currency, IxfiCurrencyService, IxfiLanguageService, Language } from 'header';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';
import { VendorService } from '../../../vendor/shared/services/vendor.service';
import { IntercomService } from '../intercom.service';
import { AuthService } from '../auth.service';
import { HttpErrorResponse } from '@angular/common/http';

interface AppResolverData {
    language: Language[];
    currency: Currency[];
    serverTime: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver {
    constructor(
        private languageService: IxfiLanguageService,
        private currencyService: IxfiCurrencyService,
        private vendorService: VendorService,
        private authService: AuthService,
        _intercomService: IntercomService // keep the service to initialize the chat
    ) {}

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> {
        const isWebView = JSON.parse(route.queryParams[STORAGE_KEY.IS_MOBILE] || 'false');
        if (isWebView) {
            localStorage.setItem(STORAGE_KEY.IS_MOBILE, JSON.stringify(true));
        }

        return forkJoin({
            language: this.languageService.fetchLanguageList(),
            currency: this.currencyService.fetchCurrencyList(),
            serverTime: this.vendorService.getServerTime()
        }).pipe(
            catchError((error: HttpErrorResponse) => {
                // Handle error here
                console.log('AppResolver', error);
                if (error.status === 503) this.authService.redirectToPlatformUrl('server-management', true);
                // Continue resolving or redirect based on the error
                return of({});
            })
        );
    }
}
