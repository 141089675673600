import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { STORAGE_KEY } from 'src/app/shared/models/enum.constants';
import { environment } from 'src/environments/environment';
import { TicketDetail } from '../models/ticket-detail.model';
import * as moment from 'moment';
import { EventStatus, IxfiRewardsVendor } from '../constants/types.constants';
import { Campaign, CampaignContent, CampaignDateInfo, VendorTicketSlug } from '../models/campaign.model';
import {
    BLOCKGAMES,
    BOBER,
    BPOFGAMING_CAMPAIGN,
    INTOTHEBLOCK_CAMPAIGN,
    SENSE_4_FIT,
    TA_DA
} from '../constants/content.constants';
import { isPlatformBrowser } from '@angular/common';
import { PageTitleService } from 'src/app/core/services/page-title.service';

interface TicketPayload {
    status: number;
    limit: number;
}

export interface BPOFTimerEvent {
    saleEnded: boolean;
    winnerTimerStarted: boolean;
    winnerAnnounced: boolean;
}

export interface TicketDetailContent {
    topCard: {
        title: string;
        desc: string;
    };
    desc: Array<string>;
}
export interface LandingPageContent {
    ticketSection: {
        panelTitle: string;
        cardTitle: string;
        des: string;
        callToAction: string;
    };
    callToAction: string;
}

export interface BannerContent {
    title: string;
    callToAction: string;
}
export interface DynamicContent {
    landing: LandingPageContent;
    detail: TicketDetailContent;
    banner: BannerContent;
}

@Injectable({
    providedIn: 'root'
})
export class VendorService implements OnDestroy {
    serverTimeNow!: number;
    ticketDetail!: TicketDetail | any; // todo: need to remove any when we api for bgame airdrop/reward

    // Vendor's Campaign Subject
    private campaignSubject = new BehaviorSubject<Campaign>({
        content: {
            detailPage: {
                card: { title: '', subtitle: '', entity: '' },
                description: { heading: '', content: [] }
            },
            landingPage: {
                banner: {
                    callToAction: {
                        displayText: '',
                        navigateTo: ''
                    }
                },
                ticketSection: {
                    panelTitle: '',
                    cardTitle: '',
                    bgImageURL: '',
                    ticketImageURL: '',
                    description: [],
                    timerLabel: '',
                    callToAction: {
                        displayText: '',
                        navigateTo: ''
                    }
                }
            }
        },
        vendorName: 'ixfi',
        status: 'comingSoon',
        timer: new Date()
    });
    vendorCampaign$ = this.campaignSubject.asObservable();

    /**
     * This Subject emits true if the timer expires
     * Default value is set to false
     */
    private _timerExpirySubject = new BehaviorSubject<boolean>(false);
    getTimerExpiryObs = this._timerExpirySubject.asObservable();
    timeoutId!: NodeJS.Timeout;

    // meta data for airdrop pages
    // type {[key in IxfiRewardsVendor]: any}
    metaDetails = {
        BlockGames: {
            title: 'Blockgames Airdrop | Get a chance to win with IXFI',
            meta: {
                'og:description': `Collect the hottest gaming NFTs right now with BlockGames Dice and ixfi`,
                'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/reward/blockGame/blockGames_meta-image.png',
                canonical: environment.IXFI_REWARDS + 'BlockGames',
                keywords:
                    'Blockgames Airdrop, Airdrop, Earn free crypto, Earn Crypto Rewards, Participate in Airdrop, IXFI Crypto Rewards, Airdrop Event'
            }
        },
        bober: {
            title: '$Bober Airdrop | Get a chance to win with IXFI',
            meta: {
                'og:description': '$BOBER is the first meme coin that flipped the entire MultiversX',
                'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/meta/bober-meta-Image.png',
                canonical: environment.IXFI_REWARDS + 'bober',
                keywords:
                    '$Bober Airdrop, Airdrop, Earn free crypto, Earn Crypto Rewards, Participate in Airdrop, IXFI Crypto Rewards, Airdrop Event'
            }
        },
        'ta-da': {
            title: 'Ta-da Airdrop | Get a chance to win with IXFI',
            meta: {
                'og:description': 'Join the $15,000 IXFI x TADA Airdrop and contribute to AI development!',
                'og:image': 'https://dfwt0d2sx0pdr.cloudfront.net/meta/tada-meta-Image.png',
                canonical: environment.IXFI_REWARDS + 'ta-da',
                keywords:
                    'Ta-da Airdrop, Airdrop, Earn free crypto, Earn Crypto Rewards, Participate in Airdrop, IXFI Crypto Rewards, Airdrop Event'
            }
        }
    };
    pageMetaService = inject(PageTitleService);

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    claimBpofTicket(payload: {
        slug: string;
        amount: number;
    }): Observable<{ status: number; message: string; data: { tentative_points: number } }> {
        return this.http.post<{ status: number; message: string; data: { tentative_points: number } }>(
            `${environment.IXFI_API}v1/rewards/claim-bpof-ticket`,
            payload
        );
    }

    claimTicket(payload: {
        slug: string;
        amount: number;
    }): Observable<{ status: number; message: string; data: { tentative_points: number } }> {
        return this.http.post<{ status: number; message: string; data: { tentative_points: number } }>(
            `${environment.IXFI_API}v1/rewards/claim-ticket`,
            payload
        );
    }

    fetchAirdropRewardDetails(slug: string): Observable<{ message: string; status: number; data: TicketDetail }> {
        return this.http
            .get<{
                message: string;
                status: number;
                data: TicketDetail;
            }>(`${environment.IXFI_API}rewards/get-reward-details/${slug}`)
            .pipe(tap(response => (this.ticketDetail = response.data)));
    }

    /**
     * This fnc is called in the resolver.
     * Initializes all the vendor and campaign related data
     * @param campaignDates All relevant Campaign dates
     * @param vendor Name of the vendor
     * @param reload reload application ~ boolean
     */
    initializeVendorCampaign(campaignDates: CampaignDateInfo, vendor: IxfiRewardsVendor, reload?: boolean) {
        if (reload) window.location.reload();
        const ticketStatus: EventStatus = this.getTicketStatus(campaignDates);
        const timerMapperJson: { [key in EventStatus]: Date } = {
            comingSoon: campaignDates.comingSoonTime,
            onGoing: campaignDates.endingTime,
            saleEnded: campaignDates.winnerAnnouncementTime,
            winnerAnnounced: campaignDates.winnerAnnouncementTime
        };
        const contentMapperJson: { [key in IxfiRewardsVendor]: { [key in EventStatus]: CampaignContent } } = {
            bpofgaming: BPOFGAMING_CAMPAIGN,
            intotheblock: INTOTHEBLOCK_CAMPAIGN,
            ixfi: BPOFGAMING_CAMPAIGN,
            sense4fit: SENSE_4_FIT,
            BlockGames: BLOCKGAMES,
            bober: BOBER,
            'ta-da': TA_DA
        };
        this.campaignSubject.next({
            vendorName: vendor,
            status: ticketStatus,
            timer: timerMapperJson[ticketStatus],
            content: contentMapperJson[vendor][ticketStatus]
        });
    }

    private getTicketStatus = (campaignDates: CampaignDateInfo): EventStatus => {
        let status: EventStatus = 'comingSoon';
        const now = new Date((this.serverTimeNow + 1) * 1000); // Convert Client Time to Server Time
        if (now < campaignDates.comingSoonTime) status = 'comingSoon';
        else if (now >= campaignDates.comingSoonTime && now < campaignDates.endingTime) status = 'onGoing';
        else if (now >= campaignDates.endingTime && now < campaignDates.winnerAnnouncementTime) status = 'saleEnded';
        else status = 'winnerAnnounced';
        return status;
    };

    getCountdownTimer(countdownDate: Date): void {
        const interval = setInterval(() => {
            const today = new Date().getTime();
            const difference = countdownDate.getTime() - today;

            /**
             * Clear Interval after it expires and reset count
             */
            if (difference <= 0) {
                this._timerExpirySubject.next(true);
                clearInterval(interval);
            }
        }, 1000);
    }

    navigateToDetail(slug: string, vendorName: IxfiRewardsVendor = 'bpofgaming') {
        const isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
        const detailPageURL: { webview: string[]; desktopView: string[] } = {
            webview: ['/mobile', vendorName, 'details', slug],
            desktopView: [vendorName, 'details', slug]
        };

        if (isWebView) this.router.navigate(detailPageURL.webview, { queryParamsHandling: 'preserve' });
        else this.router.navigate(detailPageURL.desktopView);
    }

    getServerTime() {
        return this.http
            .get<{ status: number; data: number }>(environment.IXFI_API + 'landing-page/get-server-time')
            .pipe(
                map((res: { status: number; data: number }) => {
                    this.serverTimeNow = moment(new Date()).unix();
                    if (res.status == 200) {
                        this.serverTimeNow = res.data;
                        if (isPlatformBrowser(this.platformId)) this.setServerTime();
                    }
                    return this.serverTimeNow;
                })
            );
    }

    setServerTime() {
        this.timeoutId = setTimeout(() => {
            this.serverTimeNow = this.serverTimeNow + 1;
            this.setServerTime();
        }, 1000);
    }

    updateAirdropMeta(vendorName: IxfiRewardsVendor) {
        if (this.metaDetails[vendorName]?.title) this.pageMetaService.setTitle(this.metaDetails[vendorName].title);
        if (this.metaDetails[vendorName]?.meta) this.pageMetaService.updateMeta(this.metaDetails[vendorName].meta);
    }

    ngOnDestroy(): void {
        // Clear the timeout when the component is destroyed
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
}
