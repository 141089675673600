<div class="modal-body">
    <div class="security-center-card m-0">
        <div class="security-center-card-inner">
            <div class="verification-img d-flex justify-content-center">
                <img [src]="CDN + 'profile/kyc-inprocess-gfx.svg'" alt="kyc-inprocess" />
            </div>

            <h5 class="wallet-kyc-title">{{ 'WALLET_KYC.TITLE' | translate }}</h5>
            <p class="device-detail-text text-start">{{ 'WALLET_KYC.TEXT' | translate }}</p>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center form-area flex-column mt-5">
        <button type="button" class="btn-default btn-md purple-gradient" (click)="onSetup()">
            {{ 'WALLET_KYC.SETUP' | translate }}
        </button>
        <div class="line-text" (click)="onCancel()">
            <a>{{ 'WALLET_KYC.SKIP' | translate }}</a>
        </div>
    </div>
</div>
