import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID, inject } from '@angular/core';
import { NewReleaseModalComponent } from './app/standalone/components/new-release-modal/new-release-modal.component';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { STORAGE_KEY } from './app/shared/models/enum.constants';

@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
    ngbModal;
    private router = inject(Router);
    constructor(
        private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}
    handleError(error: any): void {
        this.ngbModal = this.injector.get(NgbModal);
        const chunkFailedMessage = /Loading chunk/;
        const unexpectedToken = /Unexpected token/;
        console.log('global error handler', error);
        if (isPlatformBrowser(this.platformId)) {
            if (chunkFailedMessage.test(error.message) || unexpectedToken.test(error.message)) {
                this.updateModal();
            } else {
                const isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
                if (!isWebView) this.router.navigate(['/']);
                else this.clearDataAndReload();
            }
        }
    }

    clearDataAndReload() {
        window.location.reload();
        if (caches?.keys?.length > 0) caches.keys().then((e: any) => e.forEach(k => caches.delete(k)));
    }
    updateModal() {
        const modalRef = this.ngbModal.open(NewReleaseModalComponent, {
            size: 'md',
            centered: true,
            backdrop: 'static',
            modalDialogClass: 'updateAvailable'
        });
        modalRef.componentInstance.data = modalRef;
        modalRef.result.then(res => {
            console.log('NewReleaseModalComponent closed:', res);
            this.clearDataAndReload();
        });
    }
}
