<div class="modal-header border-0">
    <h5 class="modal-title" *ngIf="!userBanNotice">{{ 'EARN_PAGE.TERMS_AND_CONDITION' | translate }}</h5>
    <h5 class="modal-title" *ngIf="userBanNotice">{{ 'Ban Notice' }}</h5>
</div>
<div class="modal-body terms-body">
    <div class="modal-text-scroll" id="tnCBody" [innerHTML]="safeHTMLContent"></div>
</div>
<div class="modal-footer border-0 d-flex justify-content-center">
    <ng-container *ngIf="!userBanNotice">
        <button type="button" class="btn-default btn-md purple-gradient" (click)="onClose(APP_CONSTANTS.ACCEPTED)">
            {{ 'EARN_PAGE.ACCEPT_BTN' | translate }}
        </button>
        <button
            type="button"
            class="btn-twice-outline btn-twice-outline-lg ms-2"
            (click)="onClose(APP_CONSTANTS.DECLINED)">
            {{ 'EARN_PAGE.DECLINE_BTN' | translate }}
        </button>
    </ng-container>
    <ng-container *ngIf="userBanNotice">
        <button type="button" class="btn-default btn-md purple-gradient" (click)="onClose(APP_CONSTANTS.ACCEPTED)">
            {{ 'OK' }}
        </button>
    </ng-container>
</div>
