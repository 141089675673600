export enum MODAL_TYPE {
    LOG_OUT = 'logout',
    STAKE_CONFIRMATION = 'stake_confirmation',
    YES_NO = 'yes_no'
}
export enum EARLY_ACCESS_MODAL {
    INSTRUCTION = 1,
    T_N_C = 2,
    SUCCESS = 3,
    INSUFFICIENT_POINT = 4
}
