import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';
import { IxfiCurrencyService, IxfiFinanceService, IxfiLanguageService, IxfiThemeService } from 'header';
import { AuthService } from '../../services/auth.service';
import { IntercomService } from '../../services/intercom.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-shell-mobile',
    templateUrl: './shell-mobile.component.html',
    styleUrls: ['./shell-mobile.component.scss']
})
export class ShellMobileComponent {
    token!: string;
    isDarkMode!: boolean;
    constructor(
        activateRoute: ActivatedRoute,
        theme: IxfiThemeService,
        languageService: IxfiLanguageService,
        cookieService: CookieService,
        currencyService: IxfiCurrencyService,
        authService: AuthService,
        financeService: IxfiFinanceService,
        private intercomService: IntercomService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            // clear previous data
            localStorage.clear();
            cookieService.deleteAll();

            this.token = activateRoute.snapshot.queryParams[STORAGE_KEY.TOKEN] || null;
            this.isDarkMode = JSON.parse(activateRoute.snapshot.queryParams['is_darkMode'] || 'false');
            localStorage.setItem(STORAGE_KEY.IS_MOBILE, JSON.stringify(true));
            this.intercomService.shutDownChat();

            /**
             * Update Theme
             */
            theme.toggleTheme(this.isDarkMode);

            if (this.token) {
                cookieService.set(STORAGE_KEY.TOKEN, this.token, undefined, '/', environment.DOMAIN, true);
                cookieService.set(
                    STORAGE_KEY.IS_LOGGED_IN,
                    JSON.stringify(true),
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
                authService.loginStatus.next(true);
                financeService.fetchFinanceDetails();
            }

            /**
             * Update Language and Currency from Language Code and Currency Symbol
             */
            const languageCode = activateRoute.snapshot.queryParams['language_code'];
            if (languageCode) languageService.updateLanguageByCode(languageCode);

            const currencySymbol = activateRoute.snapshot.queryParams['currency_symbol'];
            if (currencySymbol) currencyService.updateCurrencyBySymbol(currencySymbol);
        }
    }
}
