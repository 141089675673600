import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastMessageService } from '../toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import { STORAGE_KEY } from '../../../shared/models/enum.constants';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    isMaintenance!: boolean;
    constructor(
        private authService: AuthService,
        private router: Router,
        private toaster: ToastMessageService,
        private translate: TranslateService,
        private cookieService: CookieService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.isMaintenance) return EMPTY; // this is to cancel the http request (existing or new)
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body.status && event.body.message && ![200, 600].includes(event.body.status))
                        this.handleError(event.body.status);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.handleError(error.status);
                let message =
                    error?.error?.message || this.translate.instant('TOASTER_MESSAGE.SOMETHING_WENT_WRONG_MESSAGE');

                this.toaster.showErrorToast({ toastMessage: message });
                return throwError(error);
            })
        );
    }

    handleError(status) {
        let errorCode = {
            451: () => {
                if (!JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false'))
                    window.location.href = environment.IXFI_EXCHANGE + 'home/welcome';
            },
            444: () => {
                if (!JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false'))
                    this.authService.redirectToPlatformUrl('auth/ixfi-pin', true);
            },
            423: () => {
                this.authService.openWalletKYCModal();
            },
            default: () => {
                if ([401, 419, 400, 417].includes(status)) {
                    if (status === 419)
                        this.cookieService.set(
                            STORAGE_KEY.RETURN_URL,
                            this.router.url,
                            undefined,
                            '/',
                            environment.DOMAIN,
                            true
                        );
                    this.authService.clearAuthData();
                } else if ([501, 503].includes(status)) {
                    this.authService.clearData();
                    if (status === 503) {
                        this.isMaintenance = true;
                        this.authService.redirectToPlatformUrl('server-management', true);
                    }
                }
            }
        };
        if (errorCode[status]) errorCode[status]();
        else errorCode['default']();
    }
}
