import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    private formatErrors(error: any) {
        return throwError(error.error);
    }

    // get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    //     return this.http.get(`${environment.STAKING_API}${path}`, { params }).pipe(catchError(this.formatErrors));
    // }

    // put(path: string, body: Object = {}): Observable<any> {
    //     return this.http.put(`${environment.STAKING_API}${path}`, body).pipe(catchError(this.formatErrors));
    // }

    // post(path: string, body: Object = {}): Observable<any> {
    //     return this.http.post(`${environment.STAKING_API}${path}`, body).pipe(catchError(this.formatErrors));
    // }

    // delete(path: string, body: Object = {}): Observable<any> {
    //     return this.http.delete(`${environment.STAKING_API}${path}`, body).pipe(catchError(this.formatErrors));
    // }

    getPlatform(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${environment.IXFI_API}${path}`, { params }).pipe(catchError(this.formatErrors));
    }

    putPlatform(path: string, body: Object = {}): Observable<any> {
        return this.http.put(`${environment.IXFI_API}${path}`, body).pipe(catchError(this.formatErrors));
    }

    postPlatform(path: string, body: Object = {}): Observable<any> {
        return this.http.post(`${environment.IXFI_API}${path}`, body).pipe(catchError(this.formatErrors));
    }

    deletePlatform(path: string, body: Object = {}): Observable<any> {
        return this.http.delete(`${environment.IXFI_API}${path}`, body).pipe(catchError(this.formatErrors));
    }
}
