import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { MODAL_TYPE } from '../../../shared/models/modal-template.model';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    standalone: true,
    imports: [TranslateModule, CommonModule]
})
export class ConfirmationModalComponent {
    @Input() modal!: { modalRef: NgbModalRef; type: MODAL_TYPE; data: any };
    CDN_URL = environment.CDN_URL;
    MODAL_TYPE = MODAL_TYPE;

    constructor(private ngbModal: NgbModal) {}

    onLogout() {
        this.modal.modalRef.close({ value: true });
    }

    onCancel() {
        this.ngbModal.dismissAll();
    }
}
